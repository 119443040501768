<template>
  <div class="mt-4">
    <div class="grey--text text--darken-2 font-weight-black">
      프로젝트 설정
    </div>

    <div>
      <DefaultMemberAuthority v-bind.sync="$data" />
      <PrivateStatus v-bind.sync="$data" />
      <ExposureStatus v-bind.sync="$data" />
    </div>
  </div>
</template>

<script>
import PrivateStatus from "./option/PrivateStatus.vue";
import ExposureStatus from "./option/ExposureStatus.vue";
import DefaultMemberAuthority from "./option/DefaultMemberAuthority.vue";

export default {
  components: { PrivateStatus, ExposureStatus, DefaultMemberAuthority },
  data({ $store: { state } }) {
    const { project } = state?.flowDialog?.params || {};
    return {
      privateStatus: project?.privateStatus ?? 0,
      exposureStatus: project?.exposureStatus ?? 1,
      defaultMemberAuthority: project?.defaultMemberAuthority || "READ"
    };
  },
  methods: {
    getData() {
      const { privateStatus, exposureStatus, defaultMemberAuthority } = this;
      return { privateStatus, exposureStatus, defaultMemberAuthority };
    }
  }
};
</script>
